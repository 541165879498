import React, { Fragment } from "react"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  Box,
  CircularProgress,
  Typography,
  Link as StyleLink,
} from "@material-ui/core"
import { AccountCircle as AccountIcon } from "@material-ui/icons"
import Image from "material-ui-image"

const useStyles = makeStyles({
  image: {
    transition: "transform .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
})

const Avatar = props => {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Fragment>
      <Box borderRadius="50%" overflow="hidden" marginBottom={1}>
        <StyleLink
          href={props.channelUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Box className={classes.image}>
            <Image
              src={props.channelImage}
              aspectRatio={1 / 1}
              errorIcon={
                <AccountIcon
                  style={{
                    width: "100%",
                    height: "100%",
                    color: theme.palette.text.icon,
                  }}
                />
              }
              loading={<CircularProgress size={150 / 2} />}
              style={{
                backgroundColor: theme.palette.background.default,
              }}
              alt={props.channelTitle}
              title={props.channelTitle}
            />
          </Box>
        </StyleLink>
      </Box>

      <Typography variant="h6" align="center" noWrap>
        <StyleLink
          color="inherit"
          href={props.channelUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.channelTitle}
        </StyleLink>
      </Typography>
    </Fragment>
  )
}

export default Avatar
