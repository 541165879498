import React, { Fragment, useEffect, useContext } from "react"
import { Link as RouterLink } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import axios from "axios"

import { useTheme } from "@material-ui/core/styles"
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  Link as StyleLink,
  useMediaQuery,
  Paper,
  Button,
  Divider,
} from "@material-ui/core"

import SEO from "../components/seo"
import Avatar from "../components/avatar"

import sortByRandomOrder from "../functions/sortByRandomOrder"

import { appContext } from "../context/provider"

const Channels = () => {
  const { channelImage } = useStaticQuery(
    graphql`
      query {
        channelImage: file(
          relativePath: { eq: "randopourlesnuls-chaine.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 200) {
              src
            }
          }
        }
      }
    `
  )

  const theme = useTheme()

  const isXs = useMediaQuery(theme => theme.breakpoints.only("xs"))
  const isSm = useMediaQuery(theme => theme.breakpoints.only("sm"))
  const isMd = useMediaQuery(theme => theme.breakpoints.only("md"))
  const isLg = useMediaQuery(theme => theme.breakpoints.only("lg"))
  const isXl = useMediaQuery(theme => theme.breakpoints.only("xl"))

  const { appData, setAppData } = useContext(appContext)

  useEffect(() => {
    console.log("=== Channels ===")
  })

  useEffect(() => {
    if (!appData.channels && appData.ids) {
      console.log("[ LOAD ] ==> appData.channels")

      const channelsIds = appData.ids.channels
      let youtubeChannels = []

      channelsIds.length > 0 &&
        axios
          .get(
            `https://www.googleapis.com/youtube/v3/channels?key=${
              process.env.GATSBY_GOOGLE_API_KEY
            }&id=${channelsIds.join(
              ","
            )}&part=snippet&fields=items(id,snippet(title, thumbnails))`
          )
          .then(result => {
            const channels = result.data.items
            //   console.log("channels", channels.length)

            channels.forEach(item => {
              const {
                id,
                snippet: { title, thumbnails },
              } = item

              let channelData = {
                id: id,
                index: channelsIds.findIndex(index => index === id),
                title: title,
                url: `https://www.youtube.com/channel/${id}`,
                image: thumbnails.medium.url,
              }

              youtubeChannels = [...youtubeChannels, channelData]

              if (youtubeChannels.length === channels.length) {
                // console.log(youtubeChannels)
                const nextYoutubeData = sortByRandomOrder(youtubeChannels)
                const nextAppData = { ...appData, channels: nextYoutubeData }
                setAppData(nextAppData)
                // console.log("appData", nextAppData)
              }
            })
          })
    }
  }, [appData, setAppData])

  return (
    <Fragment>
      <SEO title="Les participants" slug="/channels" />

      <Typography paragraph variant="h4">
        Les participants {appData.channels && `(${appData.channels.length})`}
      </Typography>

      <Typography>
        RandoPassion est une collaboration de vidéastes francophones (France,
        Suisse, Belgique, Québec) passionnés de randonnée. RandoPassion
        rassemble des témoignages de randonneuses et de randonneurs aux profils,
        pratiques et expériences variés.{" "}
        <StyleLink component={RouterLink} to="/about">
          Lire la suite...
        </StyleLink>
      </Typography>

      <Box marginY={4}>
        <Divider />
      </Box>

      <Grid
        container
        justify={appData.channels ? "flex-start" : "center"}
        spacing={isXs ? 2 : isSm ? 4 : isMd ? 6 : isLg ? 8 : isXl ? 10 : null}
      >
        {appData.channels ? (
          <Fragment>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <Avatar
                channelTitle={"La Rando Pour les Nuls"}
                channelImage={channelImage.childImageSharp.fluid.src}
                channelUrl={"https://www.facebook.com/LaRandoPourLesNuls"}
              />
            </Grid>

            {appData.channels.map(channel => (
              <Grid item xs={6} sm={4} md={3} lg={2} key={channel.index}>
                <Avatar
                  channelTitle={channel.title}
                  channelImage={channel.image}
                  channelUrl={channel.url}
                />
              </Grid>
            ))}
          </Fragment>
        ) : (
          <Box marginY={8} align="center">
            <Paper elevation={3}>
              <Box padding={4}>
                {true ? (
                  <Fragment>
                    <CircularProgress />
                    <Typography>Chargement des chaînes...</Typography>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Typography paragraph>
                      Patience, les chaînes arrivent dans quelques jours ;-)
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      component={RouterLink}
                      to="/"
                    >
                      Voir la liste des vidéos
                    </Button>
                  </Fragment>
                )}
              </Box>
            </Paper>
          </Box>
        )}
      </Grid>
    </Fragment>
  )
}

export default Channels
